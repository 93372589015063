import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";

const VideoIntro = ({ onSkipReplayVideo, isPresentation }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  const onSkipVideo = () => {
    onSkipReplayVideo();
  };

  return (
    <div className="wrap-video-intro">
      <video
        ref={videoRef}
        autoPlay={true}
        onEnded={onSkipVideo}
        preload="auto"
        id="intro-video-2"
      >
        <source src="/uploads/videos/2269289e.webm" type="video/webm" />
      </video>
      <button onClick={onSkipVideo} className={`btn-skip-video btn btn-light ${isPresentation ? 'disable-action' : ''}`}>
        {t('common.skipVideo')}
      </button>
    </div>
  );
};

export default VideoIntro;
