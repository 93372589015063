import React, { useEffect, useRef } from "react";
import "./style.scss";
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL } from "../../constants/options";
import { useState } from "react";
import LoadingIcon from "../loading-icon";
import { useDispatch, useSelector } from "react-redux";
import { reqSetPage } from "../../reduxs/home/action";
import {
  reqSetIsShowCreateInvestorProfileModal,
  reqSetIsShowCreateTenantProfileModal,
  reqSetIsShowUserTypeModal,
} from "../../reduxs/guide-session/action";
import UserTypeModal from "../guide-sesions/user-type-modal";
import ListUserSelectModal from "../guide-sesions/list-user-select-modal";
import CreateUserProfileModal from "../guide-sesions/create-user-profile-modal";
import { reqGetUserProfile } from "../../reduxs/user/action";
import { USER_GROUP } from "../../constants/master-data";
import { reqSetIsShowReplayVideo } from "../../reduxs/precinct-explore/action";
import { useTranslation } from "react-i18next";
import socket from "../../helper/socket";
import VideoIntro from "../video-intro";
import { EnableAutoPlayModal } from '../guide-sesions/enable-audio-modal';

const Loading = (props) => {
  const { t } = useTranslation();
  const { isLoading, setIsIntroduction, isPresentation } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const customer = useSelector((state) => state.user.customer);

  useEffect(() => {
    dispatch(reqGetUserProfile());
  }, []);

  useEffect(() => {
    if (authUser) {
      analytics.identify(authUser.id, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.SATRT_SESSION) {
          onStartSession();
        }
        if (content.action === ACTION_NAME.SKIP_SESSION_VIDEO) {
          startDiscover();
        }
      });
    }
  }, [isPresentation]);

  const startDiscover = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SKIP_SESSION_VIDEO);
    }
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
    analytics.track("Agent Started Session", {
      agentId: authUser?.id,
      clientId: customer?.id,
      clientName: customer?.name,
      clientEmail: customer?.email,
      clientPhone: customer?.phone,
    });
  };

  const onExplore = () => {
    dispatch(reqSetIsShowUserTypeModal(true));
  };

  const onStartSession = () => {
    if (!isPresentation) {
      socket.emitUIActionEvent(ACTION_NAME.SATRT_SESSION);
    }

    dispatch(reqSetIsShowReplayVideo(true));
    setShowVideo(true);
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
  };

  const renderExploreButton = () => {
    if (!authUser) return;

    if (
      [USER_GROUP.INVESTOR, USER_GROUP.CONSULTANT].includes(
        authUser?.userGroup.name
      )
    ) {
      return (
        <button className="explore_button" onClick={() => startDiscover()}>
          {t("DISCOVER")}
        </button>
      );
    }

    if ([USER_GROUP.ADMIN, USER_GROUP.AGENT].includes(authUser?.userGroup.name)) {
      return (
        <button className="explore_button" onClick={() => onExplore()}>
          {t("DISCOVER")}
        </button>
      );
    }
  };

  return (
    <div id="loading-screen">
      <div id="loading-cube-wrapper">
        {/*<Cube draggable={false} disabled={isLoading} onClick={startDiscover} />*/}
      </div>
      <div className={`wrap-intro ${isShowVideo ? "hide" : "show"}`}>
        <div
          id="intro-video"
          style={{ backgroundImage: `url("uploads/images/poster.png")` }}
        />

        <div className="intro-content">
          <div className="wrap-text">
            <span>{t("A NEW HOME")}</span><br/>
            <span>{t("HAVEN & HUB IN BAKU")}</span>
          </div>
          {!!isLoading && <LoadingIcon />}
          <div className={`wrap-button ${isLoading ? "disable-btn" : ""}`}>
            {renderExploreButton()}
          </div>
          {/* Used to cache video */}
          <div style={{ display: "none" }}>
            <video
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
            >
              <source src="/uploads/videos/2269289e.webm" type="video/webm" />
            </video>
          </div>
        </div>
      </div>
      <div className={`wrap-slide-loading ${isShowVideo ? "show" : "hide"}`}>
        {
          isShowVideo && <VideoIntro isPresentation={isPresentation} onSkipReplayVideo={startDiscover}/>
        }
      </div>
      <div style={{ position: "relative" }}>
        <UserTypeModal />
      </div>
      <ListUserSelectModal startDiscover={onStartSession} />
      <CreateUserProfileModal startDiscover={onStartSession} />
      {!isShowVideo && <div className="disclaimer"><span>{t('disclaimer')}</span></div>}
    </div>
  );
};

export default Loading;
